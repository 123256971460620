import {Client} from "./Webapi";
import axios from "axios";
import {LocalStorageManager} from "../localStorageManager";
import {toast} from "react-toastify";

export class ApiClientFactory {
    token: string | null;
    apiUrl: string|null = null;

    constructor(token: string | null = null) {
        this.token = token;
        if(process.env.REACT_APP_API) {
            this.apiUrl = process.env.REACT_APP_API;
        }
    }

    create() {
        let instance = axios.create({baseURL: this.apiUrl,headers: {'Authorization': 'Bearer ' + this.token}});
        instance.interceptors.response.use((response) => {
            return response.data;
        }, (error) => {
            console.debug(error);
            if (error.response) {
                if (error.response.status === 401) {
                    toast('Prosim, znovu se prihlaste', {
                        type: toast.TYPE.ERROR,
                    })
                    ;
                } else if (error.response.status === 403) {
                    console.debug('vyhodit oznameni neni pristup');
                    toast("Nemate pristup k teto funkcionalite.")
                } else if (error.response.status === 500) {
                    console.debug('vyhodit oznameni posral se server', error.response);
                    toast("Chyba serveru.")
                } else if (error.response.status === 404) {
                    return null;
                }
            } else if (process.env.REACT_APP_TEST) {
                toast("Testování. Nelze se připojit k API")
            } else {
                toast("Nelze získat odpověď od serveru.")
            }
        });

        return new Client(process.env.REACT_APP_API, instance);
    }

    createAuthLS(): Client {
        let user = new LocalStorageManager().getUser();
        if (user) {
            this.token = user.token;
        } else {
            console.log('Nelze najit local storage token');
            // getHistory().push('/login');
        }
        return this.create();
    }
}