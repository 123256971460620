import * as React from "react";
import {useEffect, useState} from "react";
import {Priloha, Soubor} from "../models/soubor";
import {Editor} from "primereact/editor";
import {LocalStorageManager} from "../services/localStorageManager";
import {PostsManager} from "../services/datamanagers/postsManager";
import {Post} from "../models/post";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {SELECTORS} from "../services/store";
import {CircularProgress, createStyles, makeStyles, Modal, Theme} from "@material-ui/core";
import {Calendar} from 'primereact/calendar';
import {FileManager} from "../services/datamanagers/fileManager";
import getHistory from 'react-router-global-history';

const user = new LocalStorageManager().getUser();
const _postManager = new PostsManager();
const _fileManager = new FileManager();
const folderPath = process.env.REACT_APP_API as string + /files/;

export const PostEditor = () => {
    const [editingPost, setPost] = useState<Post>({
        id: 0,
        title: "",
        files: [],
        text: "",
        userId: user.id,
        prilohy: [],
        nahledova: null,
        nahledovaId: null,
        created: new Date()
    })
    const [fileLightBox, setFileLightbox] = useState([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<Soubor>(null);

    const post = useSelector(SELECTORS.getLast);
    useEffect(() => {
        if (post) {
            setIsFetching(true);
            _postManager.getPostsFiles(post.id).then((res) => {
                let tempPost = post;
                res.map(x=>{
                    if(x.id == tempPost.nahledovaId) {
                        x.nahledova = true;
                    }
                    x.filePath = folderPath+x.filePath;
                    x.thumbPath = folderPath+x.thumbPath;
                });
                tempPost.files = res;
                setPost(tempPost);
            }).finally(() =>
                setIsFetching(false));
        }
    }, []);

    const fileInputChangeHandler = async (e) => {
        let tempPole = editingPost.files;
        let pocet = e.target.files.length;
        for (let i = 0; i < e.target.files.length; i++) {
            const eventFile = e.target.files[i];
            let reader = new FileReader();

            reader.onloadend = () => {
                let tempFoto = new Soubor(0, 0, eventFile.name, URL.createObjectURL(eventFile), null, eventFile.type, eventFile);
                tempPole.push(tempFoto)
                if (pocet === tempPole.length) {
                    setPost({...editingPost, files: tempPole});
                    let imagesSources = tempPole.map(x => {
                        return {source: x.filePath, thumbnail: x.thumbPath ? x.thumbPath : x.filePath, title: x.name}
                    });
                    setFileLightbox(imagesSources);
                }
            }
            reader.readAsDataURL(eventFile);
        }
    };
    const prilohyInputChangeHandler = async (e) => {
        let tempPole = editingPost.prilohy;
        let pocet = e.target.files.length;
        for (let i = 0; i < e.target.files.length; i++) {
            const eventFile = e.target.files[i];
            let reader = new FileReader();

            reader.onloadend = () => {
                let tempFoto = new Priloha(0, 0, eventFile.name, URL.createObjectURL(eventFile), eventFile);
                tempPole.push(tempFoto)
                if (pocet === tempPole.length) {
                    setPost({...editingPost, prilohy: tempPole});
                }
            }
            reader.readAsDataURL(eventFile);
        }
    };
    const nahledovaHandler = async (e) => {
        let tempPole = editingPost.files.filter(x=>!(x.nahledova && x.id === 0));
        if (e.target.files) {
            const eventFile = e.target.files[0];
            let reader = new FileReader();

            reader.onloadend = () => {
                let tempFoto = new Soubor(0, 0, eventFile.name, URL.createObjectURL(eventFile), null, eventFile.type, eventFile);
                tempFoto.nahledova = true;
                tempPole.push(tempFoto)
                setPost({...editingPost, nahledovaId:0, files: tempPole});
            }
            reader.readAsDataURL(eventFile);
        }
    };

    let toolbarOptions = [
        [{'size': ['small', false, 'large', 'huge']}],
        ['bold', 'italic', 'underline'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        ['link', 'video'],
        [{'color': []}, {'background': []}],          // dropdown with defaults from theme
        [{'font': []}],
        [{'align': []}],
        ['clean']
    ];

    const saveHandler = (e) => {
        console.log(e);
        const save = async () => {
            return new Promise((resolve,reject) => {
                if (editingPost.id == 0) {
                    _postManager.savePost(editingPost).then((res) => {
                        resolve();
                    }).catch(()=> {reject()});
                } else {
                    _postManager.updatePost(editingPost).then(()=> {
                        toast.success("Post uložen.")
                        getHistory().push('/');
                    });
                }
            });
        }

        save().then(()=> {
            toast.success("Post uložen.")
            getHistory().push('/');
        }).catch(()=> {
            toast.error("Nepovedlo se uložit post.")
        })

    }

    const returnNahledova = () => {
        let path:string;
        const tempPole = editingPost.files.filter(x => (editingPost.nahledovaId && editingPost.nahledovaId == x.id) || (editingPost.nahledovaId === 0 && x.nahledova));
        if(tempPole.length > 0){
            path = tempPole[tempPole.length-1].filePath;
        }
        return path;
    }

    return (
        <div className="container">
            {isFetching ? <CircularProgress/> :
                (<div>
                    <div className="row">
                        <div className="col-6">
                            {editingPost.files.filter(x => x.nahledova).length > 0 &&
                            <div style={{width: "150px", height: "80px"}}>
                                <img src={returnNahledova()}
                                     style={{width: "100%", height: "100%"}}/>
                            </div>}
                            <form>
                                <div className="form-group">

                                    <label htmlFor="avatar">
                                        <i style={{marginRight: "10px"}} className="fas fa-cloud-upload-alt"></i>
                                        <span>Nahrát náhledový obrázek</span>
                                    </label>
                                    <input type="file" className="form-control-file" id="avatar"
                                           style={{display: "none"}}
                                           onChange={nahledovaHandler}/>
                                </div>
                            </form>
                        </div>
                        <div className="col-4">
                            <Calendar value={editingPost.created} dateFormat="dd.mm.yy"
                                      onChange={(e) =>
                                          //@ts-ignore
                                          setPost({...editingPost, created: e.value})} showIcon={true} />
                        </div>
                        <div className="col-1">
                            <button className="btn" onClick={saveHandler}><i className="far fa-save"
                                                                             style={{fontSize: "40px"}}></i></button>
                        </div>
                    </div>
                    <div>
                        <input className="form-control input-sm" type="text" placeholder="Titulek.."
                               value={editingPost.title}
                               onChange={event => setPost({...editingPost, title: event.target.value})}/>
                    </div>
                    <Editor style={{height: "250px"}} value={editingPost.text} headerTemplate={(<div></div>)}
                            onTextChange={(e) => setPost({...editingPost, text: e.htmlValue})} modules={{
                        toolbar: {
                            container: toolbarOptions,
                            handlers: {
                                "image": () => {
                                    console.log("prdel")
                                }
                            }
                        }
                    }}/>
                    <div>
                        <form>
                            <div className="form-group">
                                <label htmlFor="images"><i style={{marginRight: "10px"}}
                                                           className="fas fa-cloud-upload-alt"></i><span>Nahrát obrázky</span></label>
                                <input type="file" className="form-control-file" id="images" style={{display: "none"}}
                                       multiple onChange={fileInputChangeHandler}/>
                            </div>
                        </form>
                    </div>
                    <div className="row overflow-auto" style={{height:"200px"}}>
                        {editingPost.files && editingPost.files.filter(x => !x.nahledova).map((x, key) => {
                            return (
                                <div className="col-md-4" key={key}>
                                    <button  onClick={() => {
                                        if(x.id && x.id > 0) {
                                            _fileManager.deleteFile(x.id);
                                        }
                                        let tempPole = editingPost.files.filter(t => t.id != x.id && t.filePath != x.filePath);
                                        console.log(editingPost.files)
                                        setPost({...editingPost, files: tempPole});}}>x</button>
                                    <div className="thumbnail">
                                        <a onClick={() => {
                                            setOpen(true);
                                            setSelectedFile(x);
                                        }}>
                                            <img src={x.thumbPath ? x.thumbPath : x.filePath} alt="Lights"
                                                 style={{width: "100%"}}/>
                                            <div className="caption overflow-hidden">
                                                <span>{x.name}</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div>
                        <form>
                            <div className="form-group">
                                <label htmlFor="files"><i style={{marginRight: "10px"}}
                                                          className="fas fa-cloud-upload-alt"></i><span>Nahrát přílohy</span></label>
                                <input type="file" className="form-control-file" id="files" style={{display: "none"}}
                                       multiple onChange={prilohyInputChangeHandler}/>
                            </div>
                        </form>
                    </div>
                    {editingPost.prilohy && editingPost.prilohy.map((x, key) => {
                        return (
                            <div className="d-flex" key={key}>
                                <div className="p-2">{x.fileName}</div>
                                <div className="ml-auto p-2">
                                    <a href={x.filePath} target="_blank"><button className="btn btn-light"><i className="fas fa-download"></i>
                                    </button></a>
                                    <button className="btn btn-light" onClick={() => {
                                        if(x.id && x.id > 0) {
                                            _fileManager.deleteAttench(x.id);
                                        }
                                        let tempPole = editingPost.prilohy.filter(t => t.id != x.id && t.filePath != t.filePath);
                                        setPost({...editingPost, prilohy: tempPole});
                                    }}><i className="fas fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                    <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={open}
                        onClose={() => setOpen(false)}
                    >
                        {selectedFile && <img style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            maxWidth: "80%",
                            height: "80%",
                            position: "relative" as "relative",
                            maxHeight: "100%",
                            width: "auto"
                        }} src={selectedFile.filePath}/>}
                    </Modal>
                </div>)}
        </div>
    );
}

const guid = (): string => {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
}
export default PostEditor;