import React, {useEffect, useState} from "react";
import Post from "../models/post";
import {PostsManager} from "../services/datamanagers/postsManager";
import {Lightbox} from "primereact/lightbox";
import {Calendar} from "primereact/calendar";
import moment from "moment";
import {pathToFileURL} from "url";
import {Link} from "react-router-dom";

const folderPath = process.env.REACT_APP_API as string + /files/;


export const PostDetail = (props: any) => {
    return (
        <div>
            <Detail id={props.match.params["id"]}/>
        </div>
    );
}

interface IDetailProps {
    id: number | null;
}

const Detail = (props: IDetailProps) => {
    const _postManager = new PostsManager();
    const [post, setPost] = useState<Post | null>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    useEffect(() => {
        setIsFetching(true);
        if (props.id) {
            _postManager.getPost(props.id).then(
                res => {
                    if (res) {
                        setPost(res);
                    }
                }
            ).finally(() => setIsFetching(false))
        }
    }, [])

    let imagesSources = post && post.files && post.files.filter(x => x.id != post.nahledovaId).map(x => {
        return {source: folderPath + x.filePath, thumbnail: folderPath + x.thumbPath, title: x.name}
    });
    return (
        <div>
            {isFetching ? <div></div> :
                (<div className="container pt-3">
                    <div className="col-12 px-0 text-red text-right py-3">
                        <Link className="red-button" to="/">Zobrazit všechny příspěvky</Link>
                    </div>
                    {/*{post && post.nahledova &&*/}
                    {/*<div>*/}
                    {/*    <img className="post-detail-img pb-3" src={post.nahledova.filePath}/></div>}*/}
                    {post && <div><h1 className="first-red-bold">{post.title}</h1>
                        <span className="text-bold">{moment(post.created).format("DD.MM.YYYY")}</span>
                        <div dangerouslySetInnerHTML={{__html: post.text}}></div>
                    </div>}
                    {post && post.prilohy && post.prilohy.map((x, key) => {
                        return (
                            <div className="d-flex" key={key}>
                                <div className="p-2">{x.fileName}</div>
                                <div className="ml-auto p-2">
                                    <a href={folderPath + x.filePath} target="_blank">
                                        <i className="fas fa-download"></i>
                                    </a>
                                </div>
                            </div>
                        )
                    })}
                    {post && post.files &&
                    <div className="images-lightbox"><Lightbox images={imagesSources}/></div>
                    }</div>)
            }
        </div>);
}
export default PostDetail;