import {User} from "../../../models/user";
import {PREFIXES} from "./utils";
interface IState {
    prihlasen: User|null;
}

let authState:IState = {
    prihlasen: null
};

const TYPES = {
    PRIHLAS_UZIVATELE: PREFIXES.PRIHLASEN};

const loginAC = (user:User) => {
    return {type: TYPES.PRIHLAS_UZIVATELE, payload: user};
};

const login = (user:User) => {
    return loginAC(user);
};

export const authReducer = (state:IState = authState, action:{type:string, payload:any})=>{
    switch (action.type) {
        case TYPES.PRIHLAS_UZIVATELE:
            return {...state, prihlasen: action.payload};
        default:
            return state;
    }
};

export const operations = {login};