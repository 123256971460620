import {PREFIXES} from "./utils";
import Post from "../../../models/post";
interface IState {
    posts: Post[];
    last: Post|null;
}

let authState:IState = {
    posts: [],
    last: null
};

const TYPES = {
    ALL: PREFIXES.ALL + 'ALL',
    LAST: PREFIXES.LAST
};

const getAllAC = (posts: Post[]) => {
    return {type: TYPES.ALL, payload: posts}
}

const getLastAC = (post: Post) => {
    return {type: TYPES.LAST, payload:post};
}
const uvolniPostAC = () => {
    return {type: TYPES.LAST, payload:null};
}
const getAll = (posts: Post[]) => {
    return getAllAC(posts);
}
const getLast = (post: Post) =>  {
    return getLastAC(post);
}
const uvolniPost = () =>{
    return uvolniPostAC();
}

export const postReducer = (state:IState = authState, action:{type:string, payload:any})=>{
    switch (action.type) {
        case TYPES.LAST:
            return {...state, last: action.payload};
        case TYPES.ALL:
            return {...state, posts: action.payload};
        default:
            return state;
    }
};

export const operations = {getAll, getLast, uvolniPost};