import {User} from "../models/user";

export interface ILocalStorageManager {
    getUser():User|null;
    saveUser(u:User):void;
    removeUser():void;
}

export class LocalStorageManager implements ILocalStorageManager{
    getUser():User|null{
        let token = null;
        try {
            let a = localStorage.getItem('user');
            if(a!==null){
                token = JSON.parse(a) || null;
            }
        } catch (e) {
            token = null;
        }
        return token;
    }

    saveUser(uzivatel:User):void{
        localStorage.setItem('user', JSON.stringify(uzivatel));
    }

    removeUser():void{
        localStorage.removeItem('user');
    }
}