import React from 'react';
import './App.css';
import {HashRouter as Router, Link, Route, Switch} from "react-router-dom";
import PostsScene from "./scenes/postsScene";
import LoginScene from "./scenes/loginScene";
import PostDetail from "./components/postDetail";
import {applyMiddleware, compose, createStore} from "redux";
import promiseMiddleware from 'redux-promise';
import thunk from "redux-thunk";
import reducer from "./services/store";
import {Provider, useDispatch} from "react-redux";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {PostEditor} from "./components/editor";
import {ReactRouterGlobalHistory} from 'react-router-global-history';
import {LocalStorageManager} from "./services/localStorageManager";

const rootReducer = (state: any, action: any) => {
    // middleware redux pro reset store
    if (action.type === 'USER_LOGOUT') {
        state = undefined
    }
    return reducer(state, action);
};

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export let store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk, promiseMiddleware)));

toast.configure();

const App = () => {

    let user = new LocalStorageManager().getUser();
    return (
        <Provider store={store}>
            <Router>
                <div>
                    <ReactRouterGlobalHistory/>
                    <ToastContainer/>
                    <nav className="navbar navbar-dark bg-black" style={{display: "inline-block", width: "100%"}}>


                        <div className="d-flex justify-content-between align-items-center py-3">
                            <a href="https://www.proconom.cz/" className="h-100 d-flex align-items-center">
                                <img className="logo-menu" src="/img/proconom_logo_dlouhe.svg"
                                     alt="Proconom dlouhé logo"/>
                            </a>
                            {user && <div>
                                <Link className="navbar-brand float-right" to="/login">
                                    <button className="btn btn-light"><i className="fas fa-sign-in-alt"></i></button>
                                </Link>
                            </div>}
                        </div>
                    </nav>
                    <Switch>
                        <Route path="/post/:id" children={PostDetail}/>
                        <Route path="/editor" component={PostEditor}/>
                        <LoginPage exact={true} path="/login" component={LoginScene}/>
                        <Route path="/" component={PostsScene}/>
                    </Switch>
                </div>
            </Router>
        </Provider>
    );
}
//@ts-ignore
const LoginPage = (props: any) => {
    return (
        <Route path={props.path} children={props.component}/>
    )
};
export default App;
