import {AuthAdapter, User} from "../../models/user";
import {Client} from "../api/Webapi";
import {useDispatch} from "react-redux";
import {operations} from "../store/auth/operations";

export class AuthManager {
    private _client = new Client(process.env.REACT_APP_API);
    private _authAdapter = new AuthAdapter();

    async loginUser(user: User): Promise<User | null> {
        if (user.username && user.password) {
            let res = await this._client.auth_Login(this._authAdapter.toApi(user));
            if (res) {
                //@ts-ignore
                let data = res["data"];
                let tempUser = new User(data["name"], null, data["id"], data["token"], data["role"]);
                return tempUser;
            }
        }
        return null;
    }

}