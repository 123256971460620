import {Client} from "../api/Webapi";
import {ApiClientFactory} from "../api/apiClient";
import Post, {PostAdapter} from "../../models/post";
import axios from "axios";
import {FileManager} from "./fileManager";
import {Soubor, SouborAdapter} from "../../models/soubor";

export class PostsManager {
    private _clientPublic = new ApiClientFactory().create();
    private _postAdapter = new PostAdapter();
    private _fileManager = new FileManager();
    private _clientAuth = new ApiClientFactory().createAuthLS();
    private _souborAdapter = new SouborAdapter();

    async getPost(id: number): Promise<Post|null> {
        let res = await this._clientPublic.posts_GetPosts(id);
        if (res) {
            return this._postAdapter.fromApi(res);
        }
        return null;
    }

    async getAllPosts(): Promise<Post[]> {
        let res = await this._clientPublic.posts_GetPostsAll();
        console.debug(res);
        if (res && res.length > 0) {
            return res.sort((a,b) => {
                if ( a.created < b.created ){
                    return 1;
                }
                if ( a.created > b.created ){
                    return -1;
                }
                return 0;
            }).map(value => {
                return this._postAdapter.fromApi(value);
            });
        }
        return [];
    }

    async deletePost(id: number) {
        let res = await this._clientAuth.posts_DeletePosts(id);
    }
    async savePost(post: Post) {
        let res = this._clientAuth.posts_PostPosts(this._postAdapter.toApi(post)).then(
            res => {
                if(res) {
                    console.log(post.files.filter(x=>x.nahledova));
                    if(post.files && post.files.length > 0) {
                        let tempPole = post.files.map((x) => {
                            x.postId = res.id;
                            return x;
                        })
                        tempPole.map(x=> { this._fileManager.sentFile(x)});
                    }
                    if(post.prilohy && post.prilohy.length > 0) {
                        console.log(post.prilohy)
                        let tempPole = post.prilohy.map((x) => {
                            x.postId = res.id;
                            return x;
                        })
                        tempPole.map(x=> { this._fileManager.sentPriloha(x)});
                    }
                    if(post.files.filter(x=> x.nahledova).length > 1) {
                        let tempPole = post.files.filter(x=> x.nahledova);
                        for(let i = 0; i < tempPole.length -1; i++ ) {
                            if(tempPole[i].id > 0) {
                                this._fileManager.deleteFile(post.files.filter(x => x.nahledova && x.id != post.nahledovaId)[0].id)
                            }
                        }
                    }
                }
            }
        );
    }
    async updatePost(post: Post) {
        let res = await this._clientAuth.posts_PutPosts(post.id, this._postAdapter.toApi(post));
        if(post.files && post.files.filter(x=>x.id == 0).length > 0) {
            let tempPole = post.files.filter(x=>x.id == 0).map((x) => {
                x.postId = post.id;
                return x;
            })
            tempPole.map(x=> { this._fileManager.sentFile(x)});
        }
        if(post.prilohy && post.prilohy.filter(x=>x.id == 0).length > 0) {
            console.log(post.prilohy)
            let tempPole = post.prilohy.filter(x=>x.id == 0).map((x) => {
                x.postId = post.id;
                return x;
            })
            tempPole.map(x=> { this._fileManager.sentPriloha(x)});
        }
    }

    async getPostsFiles(id:number): Promise<Soubor[]> {
        let res = await this._clientAuth.posts_GetFilesByPost(id);
        if(res) {
            return res.map(x=>{
                return this._souborAdapter.fromApi(x);
            })
        }
        return [];
    }
}