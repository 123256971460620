import {ApiClientFactory} from "../api/apiClient";
import {Priloha, Soubor, SouborAdapter} from "../../models/soubor";

export class FileManager{
    private _client = new ApiClientFactory().create();
    private _clientAuth = new ApiClientFactory().createAuthLS();
    private _fileAdapter = new SouborAdapter();
    getAllFile() {
        this._client.files_GetFilesAll();
    }
    getFile(id:number) {
        this._client.files_GetFiles(id);
    }
    deleteFile(id:number) {
        this._clientAuth.files_DeleteFiles(id);
    }
    deleteAttench(id:number) {
        this._clientAuth.attachments_DeleteAttachments(id);
    }
    sentFile(file:Soubor) {
        this._clientAuth.files_PostFiles({data: file.data , fileName:file.name}, file.postId, file.nahledova);
    }
    sentPriloha(file:Priloha) {
        this._clientAuth.attachments_PostAttachments({data: file.data , fileName:file.fileName}, file.postId);
    }
}