import {default as postsReducer} from "./posts";
import {default as authReducer} from "./auth";
import {combineReducers} from "redux";
import {User} from "../../models/user";

const reducers = {
    posts: postsReducer,
    auth: authReducer
};

const reducer = combineReducers(reducers);
export default reducer;

export const SELECTORS = {
    //auth
    getUser: (state:any):User|null => state.auth.user.prihlasen,

    //posts
    getAll: (state:any) => state.posts.postOP.posts,
    getLast: (state:any) => state.posts.postOP.last
};
