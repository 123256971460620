import React, {useEffect, useState} from "react";
import Post from "../models/post";
import {PostsManager} from "../services/datamanagers/postsManager";
import {Paginator} from 'primereact/paginator';
import {Link, Switch, Route, useRouteMatch, useParams} from "react-router-dom";
import {Toolbar} from "primereact/toolbar";
import {Button} from "primereact/button";
import {LocalStorageManager} from "../services/localStorageManager";
import {useDispatch} from "react-redux";
import {operations} from "../services/store/posts/operations";
import moment from "moment";

const _postManager = new PostsManager();

export const PostsScene = () => {

    const [first, setFirst] = useState<number>(0);
    const [rows, setRows] = useState<number>(10);
    const [posts, setPosts] = useState<Post[]>([]);
    const [showed, setShowed] = useState<Post[]>([]);

    const _user = new LocalStorageManager().getUser();
    const dispatcher = useDispatch();


    useEffect(() => {
        _postManager.getAllPosts().then(
            res => {
                if (res) {
                    console.log(res);
                    setPosts(res);
                    setShowed(res.slice(first, rows));
                }
            }
        ).finally(() => {
            console.log("done")
        });
    }, [])

    useEffect(()=> {
        setShowed(posts.slice(first, rows))
    },[posts]);

    const onDeleteHandler = (id: number) => {
        let tempPole = posts.filter(x => x.id != id);
        setPosts(tempPole);
    }

    console.debug(_user);
    return (
        <div className="container posts">
            <h1 className="text-center py-4">Naše <span className="text-red font-weight-bold"> aktuálně</span></h1>
            <div className="row">
                <div className="col-12 col-md-10 col-lg-12 col-xl-10 mx-auto">
                    <div className="row">
                        {_user && <div className="col-12 text-right pb-4"><Link className="navbar-brand" to="/editor">
                            <button className="btn btn-outline-dark btn-pridat" title="Nový příspěvek" onClick={() => {
                                dispatcher(operations.uvolniPost())
                            }}>
                                <i className="fas fa-plus"></i>
                            </button>
                        </Link></div>}
                        {
                            posts && posts.length > 0 ? showed.map((value, index) => {
                                return (
                                    <PostPreview key={index} post={value}  deleteHandler={onDeleteHandler}/>
                                );
                            }) : <div><p style={{textAlign: "center"}}>Žádné posty zde nejsou</p></div>
                        }
                        <div className="col-12 px-0">
                            <Paginator first={first} rows={rows} totalRecords={posts.length}
                                       rowsPerPageOptions={[10, 20, 30]}
                                       onPageChange={(e: any) => {
                                           setFirst(e.first);
                                           setRows(e.rows);
                                           setShowed(posts.slice(e.first, e.first + e.rows));
                                       }}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PostsScene;

interface IPostPreviewProps {
    post: Post;
    deleteHandler: (id: number) => void
}

export const PostPreview = (props: IPostPreviewProps) => {
    const dispatcher = useDispatch();

    const _user = new LocalStorageManager().getUser();
    return (
        <div className="col-12 col-lg-6">

            {props.post &&
            <div className="row list-posts">

                <div className="col-12 pb-4 pb-lg-5">

                    <h3>{props.post.title}</h3>
                    <span
                        className="text-body font-weight-bold">{moment(props.post.created).format("DD.MM.YYYY")}</span>

                    <div dangerouslySetInnerHTML={{__html: props.post.text}}></div>
                    {props.post && props.post.nahledova &&
                    <div>
                        <img className="post-detail-img" src={process.env.REACT_APP_API + '/files/' + props.post.nahledova.filePath}/></div>}

                    <div className="red-button-around">
                        <Link to={`post/` + props.post.id} className="red-button">číst</Link>
                    </div>

                    {_user && <div className="d-flex">
                        <Link to={'editor'}>
                            <button className="btn pt-0 pl-0" onClick={() => {
                                dispatcher(operations.getLast(props.post));
                            }}>
                                <i className="fas fa-edit btn-size"></i></button>
                        </Link>
                        <button className="btn pt-0 pl-0" onClick={() => {
                            _postManager.deletePost(props.post.id);
                            props.deleteHandler(props.post.id);
                        }}>
                            <i className="fas fa-times text-danger btn-size"></i></button>
                    </div>}


                </div>
            </div>}
        </div>
    );
}