import React, {useEffect, useState} from "react";
import {User} from "../models/user";
import {AuthManager} from "../services/datamanagers/authManager";
import {useDispatch, useSelector} from "react-redux";
import {operations} from "../services/store/auth/operations";
import {LocalStorageManager} from "../services/localStorageManager";
import {toast} from "react-toastify";
import getHistory from 'react-router-global-history';


const store = new LocalStorageManager();

export const Login = () => {
    const [user, setUser] = useState<User>(new User("", ""));
    const [isLogin, setIsLogin] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(true);
    const dispatcher = useDispatch();
    const _authManager = new AuthManager();


    useEffect(() => {
        if(store.getUser()) {
            store.removeUser();
        }
    }, [])

    const onChangeHandler = (event: any) => {
        const {name, value} = event.target;
        setUser({...user, [name]: value});
    }
    const submitHandler = (e: any) => {
        e.preventDefault();
        login();
    }

    const login = () => {
        _authManager.loginUser(user).then(
            res => {
                if (res) {
                    dispatcher(operations.login(res));
                    new LocalStorageManager().saveUser(res);
                    setIsLogin(true);
                    getHistory().push('/');
                }
            }).catch(e => {
            toast.error("špatné přihlašovací údaje.");
        }).finally(() => {
            toast.success("Podařilo se přihlásit.");
        });

    }

    return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 col-xl-4 mx-auto mt-5">
                {
                    isLogin ?
                        (
                            <div className="text-center">
                                <p>Vítejte</p>
                                <button className="btn btn-danger" onClick={() => {
                                    store.removeUser();
                                    setIsLogin(false);
                                }}>Odhlásit
                                </button>
                            </div>
                        ) :
                        (
                            <form onSubmit={submitHandler} method="POST">
                                <div className="form-group">
                                    <input type="text" className="form-control" id="inputUsername" name="username"
                                           placeholder="Uživatelské jméno" onChange={onChangeHandler}/>
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control" id="inputPassword" name="password"
                                           placeholder="Heslo" onChange={onChangeHandler}/>
                                </div>
                                <button type="submit" className="btn btn-danger btn-lg btn-block">Přihlásit</button>
                            </form>
                        )
                }
            </div>
                    </div>
                </div>
            </div>
    );
}
export default Login;