import {AdapterApi} from "./adapter";
import {Posts} from "../services/api/Webapi";
import {Soubor,SouborAdapter, Priloha, PrilohaAdapter} from "./soubor";
import moment from "moment";

const _souborAdapter = new SouborAdapter();
const _prilohaAdapter = new PrilohaAdapter();

export class Post{
    id:number;
    title:string;
    text:string;
    files:Soubor[]|null;
    userId: number;
    nahledovaId: number|null;
    nahledova: Soubor|null;
    prilohy: Priloha[]|null;
    created: Date|null;
    constructor(id:number, title: string, text: string, files: Soubor[]|null, userId: number, nahledovaId?:number|null, prilohy?:Priloha[]|null, nahledova?:Soubor|null, created?:Date|null) {
        this.id= id;
        this.title = title;
        this.text = text;
        this.files = files;
        this.userId = userId;
        this.nahledovaId = nahledovaId;
        this.prilohy = prilohy;
        this.nahledova = nahledova;
        this.created = created;
    }
}

export class PostAdapter implements AdapterApi<Posts, Post>{
    fromApi(item: Posts): Post {
        return new Post(item.id, item.title ? item.title: "", item.text ? item.text:"",
            item.files ? item.files.map(x=>{return _souborAdapter.fromApi(x)}):null, item.userId, item.nahledovaId,
            item.attachments ? item.attachments.map((x) => {return _prilohaAdapter.fromApi(x)}):null,
            item.nahledova ? _souborAdapter.fromApi(item.nahledova):null, new Date(item.created));
    }

    toApi(item: Post): Posts {
        return new Posts(
            {
                id: item.id,
                text: item.text,
                title:item.title,
                files:null, user: null,
                userId: item.userId,
                attachments: null,
                nahledova: null,
                nahledovaId: item.nahledovaId > 0 ? item.nahledovaId : null,
                created: item.created? moment(item.created).format("YYYY-MM-DD"):null
            });
    }

}
export default Post;