import {AdapterApi} from "./adapter";
import {Attachments, FileParameter, Files, Posts} from "../services/api/Webapi";

const folderPath = process.env.REACT_APP_API as string + /files/;

export class Soubor {
    id:number;
    postId:number;
    name:string;
    filePath: string;
    thumbPath: string;
    type: string;
    data: Blob;
    nahledova: boolean = false;

    constructor(id:number, postId:number, name:string, filePath:string|null, thumbPath:string|null, type:string, data:Blob) {
        this.id = id;
        this.postId = postId;
        this.name = name;
        this.filePath = filePath;
        this.thumbPath = thumbPath;
        this.type = type;
        this.data = data;
    }
}

export class FileContent {
    id:string;
    constructor(id:string) {
        this.id = id;
    }
}

export class FileData {
    data: any;
    mimeType: string;
    constructor(data:any, mimeType:string) {
        this.data = data;
        this.mimeType = mimeType;
    }
}

export class ImageVideoFiles extends FileContent {
    thumbNail: FileData;
    data: FileData;
    constructor(id:string, data: FileData, thumbNail: FileData){
        super(id);
        this.data = data;
        this.thumbNail = thumbNail;
    }
}

export class SouborAdapter implements AdapterApi<Files, Soubor>{
    fromApi(item: Files): Soubor {
        return new Soubor(item.id, item.postId, item.name, item.filePath, item.thumbnailPath, null, null);
    }

    toApi(item: Soubor): Files {
        return new Files();
    }

}

export class Priloha {
    id!: number;
    filePath!: string | null;
    postId!: number;
    fileName: string;
    data:Blob;

    constructor(id:number, postId:number, fileName:string, filePath?:string|null, data?:any ) {
        this.id = id;
        this.filePath = filePath;
        this.postId = postId;
        this.data = data;
        this.fileName = fileName;
    }
}

export class PrilohaAdapter implements AdapterApi<Attachments, Priloha>{
    fromApi(item: Attachments): Priloha {
        return new Priloha(item.id, item.postId,item.name, item.filePath);
    }

    toApi(item: Priloha): Attachments {
        return new Attachments({
            id: item.id,
            filePath: item.filePath,
            postId: item.postId,
            post: null,
            name: item.fileName
        });
    }
}