import React, {useState} from "react";
import Login from "../components/login";
import {User} from "../models/user";
import {useDispatch} from 'react-redux';
import {AuthManager} from "../services/datamanagers/authManager";
import {operations} from "../services/store/auth/operations";

export const LoginScene = (props:any) =>{
    return (
        <Login/>
    );
}
export default LoginScene;