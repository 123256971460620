import {Login} from "../services/api/Webapi";
import {AdapterApi} from "./adapter";

export class User
{
    id:number|null|undefined;
    username: string|null|undefined;
    password:string|null|undefined;
    token:string|null|undefined;
    role: string|null|undefined;
    constructor()
    constructor( username:string|null, password:string|null, id?:number, token?:string|null, role?:string|null)
    constructor( username?:string|null, password?:string|null,id?:number, token?:string|null, role?:string|null) {
        this.id = id;
        this.username = username;
        this.password = password;
        this.token = token;
        this.role = role;
    }
}

export class AuthAdapter implements AdapterApi<Login,User>
{
    fromApi(item: Login): User {
        return new User(item.username, item.password);
    }

    toApi(item: User): Login {
        return new Login({username: item.username?item.username:"", password: item.password?item.password:""});
    }
}